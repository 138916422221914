import React, { useEffect } from 'react';
import ProfilePicture from '../images/logos/logo_vert_blue.png';
import AOS from 'aos';
import GithubIcon from '../images/logos/github-small.png';
import { GAEvent } from '../globaluse';

type Props = {};

const MailIcon = (): JSX.Element => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			fill='none'
			viewBox='0 0 24 24'
			strokeWidth={2}
			stroke='currentColor'
			className='w-9 h-9'>
			<path
				strokeLinecap='round'
				strokeLinejoin='round'
				d='M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75'
			/>
		</svg>
	);
};

export default function IntroSection({}: Props) {
	useEffect(() => {
		AOS.init();

		const deviceType = () => {
			if (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
				return 'mobile';
			} else if (/iPad/i.test(navigator.userAgent)) {
				return 'tablet';
			} else {
				return 'desktop';
			}
		};
		if (deviceType() === 'desktop') {
			document.querySelector('.intro--about')?.classList.add('glowing');
		}
	}, []);
	return (
		<section className='flex justify-center items-center w-full min-h-[100vh] h-full overflow-hidden intro--section'>
			<div
				className='flex w-[80%] gap-10 p-16 pt-20 intro--content'
				data-aos='fade-down'
				data-aos-duration='1500'
				data-aos-delay='500'
				data-aos-once='true'>
				<div className='flex flex-col gap-3 text-white w-2/3 tracking-[1px] font-semibold'>
					<h1 className='text-[100px] leading-[120px] welcome--text'>Welcome.</h1>
					<h3 className='text-md font-light intro--about'>
						My name is Olaf Dalach. A young developer who is passionate about technology. I have
						more than 4 years of programming experience. The ability to solve problems and
						communicate well is my main strength. I can learn fast and use that knowledge throughout
						my projects.
					</h3>
					<div className='flex gap-10 mt-14 items-center icons--holder'>
						<a href='mailto:ph4sm4solutions@gmail.com'>
							<MailIcon />
						</a>
						<a
							href='https://github.com/Ph4sm4'
							target='_blank'
							onClick={() => GAEvent('Intro section', 'INTRO Github link', 'Github link click')}>
							<img src={GithubIcon} />
						</a>
					</div>
				</div>
				<img src={ProfilePicture} className='h-[400px] object-scale-down ph4sm4--logo--big' />
			</div>
		</section>
	);
}
